@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
i,
a {
  font-family: Questrial;
  font-weight: 400;
  font-style: normal;
  color: white;
  text-align: center;
}

i {
  font-style: italic;
  width: 100%;
}

ul {
  display: grid;
  gap: 1rem;
}

li {
  list-style-type: none;
  display: grid;
  grid-template-columns: 2rem auto;
  gap: 1rem;
  align-items: center;
}

li span {
  opacity: 0.64;
  text-align: left;
}

h1,
h2 {
  font-size: 3rem;
  margin: 0 2rem;
}

h3 {
  margin: 0 2rem;
}

h3,
h4,
h5,
h6,
p,
span,
i,
label {
  font-size: 1.5rem;
}

a {
  padding: 1rem;
}

button {
  /* background: linear-gradient(#ff5c00, #000000); */
  background: #0074b6;
  padding: 2rem 6rem;
  color: white;
  font-family: Questrial;
  font-size: 1.5rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 2px solid white;
}

button:hover {
  cursor: pointer;
  background: #2a9ddf;
  /* background: linear-gradient(#b64200, #161616); */
}

form {
  display: grid;
  gap: 4rem;
  padding: 1rem;
  max-width: 62rem;
  margin: 0 auto;
}

label {
  text-align: left;
  padding-left: 1rem;
  color: rgba(255, 255, 255, 0.64);
  /* color: #ff5c00; */
  font-family: Questrial;
  font-weight: 400;
  font-style: normal;
}

input {
  padding: 1rem;
  color: white;
  font-family: Questrial;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  background: rgba(255, 255, 255, 0.16);
  border: none;
}

input::placeholder {
  /* color: #b64200; */
}

.input_container,
.error_input_container {
  display: grid;
  gap: 0.5rem;
}

.error_input_container label {
  color: #ff5c00;
}

.label_error {
  color: #ff5c00;
}

.error_input_container input {
  background: rgba(182, 66, 0, 0.32);
}

nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity: 0.64;
  margin-top: 1rem;
}

nav a:hover {
  background: rgba(255, 255, 255, 0.16);
}

.question {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.12);
}

.App {
  background-color: #161616;
  min-height: 100vh;
  width: 100%;
}

.cart_container {
  background-color: #161616;
  padding: 2rem;
  color: white;
  font-family: Questrial;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-size: 1.5rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 2px solid white;
  z-index: 10;
  display: grid;
  gap: 1rem;
  position: fixed;
  margin: 1rem;
  right: 0;
  top: 0;
  max-height: 90vh;
  overflow-y: scroll;
}

.cart_add {
  padding: 1rem 2rem;
}

.cart_added {
  padding: 1rem 2rem;
  font-family: Questrial;
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid white;
  background: #161616;
  color: white;
  opacity: 0.64;
}

.cart_added:hover {
  background: black;
}

.cart_cancel {
  background: #161616;
  border: 2px solid #ffffff52;
  color: #ffffffa3;
}

.cart_cancel:hover {
  background: black;
}

.cart_remove {
  background: #161616;
  border: 2px solid rgba(255, 255, 255, 0.32);
  color: rgba(255, 255, 255, 0.32);
  padding: 1rem 2rem;
}

.cart_remove:hover {
  background: black;
}

.cart_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  gap: 1rem;
}

.cart_item p {
  text-align: left;
}

.check {
  height: 2rem;
  width: 2rem;
}

.checkbox_unchecked,
.checkbox_checked {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}

.checkbox_unchecked {
  opacity: 0;
}

.checkbox_checked {
  animation: checkpulse 2s infinite;
}

.checked_description {
  opacity: 0.64;
  text-align: left;
}

.label_checked {
  opacity: 1;
}

@keyframes checkpulse {
  0% {
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.checkbox_container {
  display: grid;
  grid-template-columns: 2rem auto;
  padding: 0.5rem;
  padding-left: 0;
  gap: 1rem;
  align-items: center;
  background: none;
  border: 0;
}

.label {
  opacity: 0.64;
}
.checkbox_container:hover {
  cursor: pointer;
  background: none;
}

.checkbox_container:hover .label {
  opacity: 1;
}

.examples {
  display: grid;
  gap: 4rem;
  padding: 0 2rem;
  margin: 3rem auto;
  margin-bottom: 0;
  max-width: 64rem;
}

.example {
  display: grid;
  grid-template-areas: "image children";
  grid-template-columns: 15rem auto;
  gap: 2rem;
  align-items: center;
}

.example_image_container {
  background: linear-gradient(to right, rgba(182, 66, 0, 0.64), #161616);
  width: 15rem;
  padding: 2rem;
  padding-right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-area: image;
  height: 100%;
}

.example_image {
  width: 100%;
  object-fit: contain;
}

.example_children {
  display: grid;
  gap: 2rem;
  align-items: center;
  align-content: flex-start;
}

.example_children h3,
.example_children span {
  margin: 0;
  text-align: left;
}

.footer {
  margin-top: 8rem;
  padding: 0 2rem;
  padding-bottom: 8rem;
  display: grid;
  gap: 2rem;
  justify-content: center;
}

.footer_logo {
  width: 100%;
  max-width: 60rem;
  opacity: 0.16;
  justify-self: center;
  margin-bottom: 6rem;
}

.link {
  background-color: #0074b6;
  padding: 1rem 2rem;
  color: white;
  font-family: Questrial;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-size: 1.5rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 2px solid white;
  box-shadow: 0.75rem -0.75rem rgba(0, 116, 182, 0.32);
}

.link:hover {
  background: #2a9ddf;
  box-shadow: 1rem -1rem rgba(0, 116, 182, 0.32);
}

.logo_container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 1rem;
}

.logo {
  height: 21px;
  margin-bottom: 3px;
  margin-right: 0.5rem;
}

.H2H3 {
  padding: 0 2rem;
  margin: 4rem auto;
  margin-top: 8rem;
}

.H2H3 h2,
.H2H3 h3 {
  margin: 0;
  padding: 0;
}

.intro {
  height: 86vh;
  position: relative;
  background: linear-gradient(#b64200, #161616);
  margin-bottom: 1rem;
}

.intro h1 {
  margin-top: 14rem;
  margin-bottom: 1rem;
}

.intro p {
  opacity: 0.64;
  max-width: 62rem;
  padding: 0 2rem;
  margin: 0 auto;
}

.intro_teaser {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.IVFs_container {
  background: rgba(182, 66, 0, 0.32);
}

.IVFs_header {
  padding: 4rem;
  margin: 0;
  margin-top: 8rem;
}

.IVFs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  padding-top: 0;
}

.IVF {
  display: grid;
  gap: 1rem;
  text-align: center;
  max-width: 24rem;
}

.IVF h3 {
  background-color: #b64200;
  padding: 1rem;
  margin: 0;
}

.o64 {
  opacity: 0.64;
}

.oj_light {
  color: #ff5c00;
}

.pricing {
  background: rgba(182, 66, 0, 0.48);
  padding: 1rem;
}

.right {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  text-align: left;
}

.right span {
  text-align: left;
}

.secondary_check {
  opacity: 0.16;

  height: 2rem;
  width: 2rem;
}

.secondary_li {
  margin-left: 3rem;
}

.snackbar {
  position: fixed;
  margin: 1rem;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: #161616;
  display: grid;
  color: white;
  z-index: 10;
  border: 2px solid white;
  font-family: Questrial;
  font-size: 1.5rem;
}

.snackbar h3 {
  background: linear-gradient(#b64200, #161616);
  padding: 1rem;
  margin: 0;
}

.snackbar h4 {
  padding: 1rem;
  opacity: 0.64;
  margin: 0;
}

.spark {
  height: 8px;
  width: 4px;
  position: fixed;
  bottom: 0;
  background: orange;
  box-shadow: 0 0 4px white;
  animation: 4s spark linear infinite;
}

@keyframes spark {
  /* 0% {
    bottom: 0;
  }
  10% {
    margin-left: 1rem;
  }
  20% {
    margin-left: 0;
  }
  40% {
    margin-left: 2rem;
  }
  100% {
    bottom: 100vh;
  } */
}

.subtle {
  color: #8bc8f9;
  text-decoration-line: underline;
  text-decoration-color: rgba(139, 200, 249, 0.32);
  padding: 0;
}

@media (max-width: 50.5rem) {
  h1,
  h2 {
    font-size: 2rem;
    margin: 0 1rem;
  }
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  i,
  label {
    font-size: 1rem;
  }
  h3 {
    margin: 0 1rem;
  }

  a {
    padding: 0.5rem;
  }

  button {
    font-size: 1rem;
    padding: 1rem 3rem;
  }

  form {
    gap: 2rem;
  }

  input {
    font-size: 1rem;
  }

  .cart_added {
    padding: 1rem 3rem;
    font-size: 1rem;
  }

  li {
    grid-template-columns: 1rem auto;
  }

  li,
  ul {
    gap: 0.5rem;
  }

  .cart_container {
    padding: 1rem;
  }

  .cart_item span {
    text-align: left;
  }

  .c_text {
    text-align: center;
  }

  .center {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .check {
    height: 1rem;
    width: 1rem;
  }

  .secondary_li {
    margin-left: 1.5rem;
  }

  .secondary_check {
    height: 1rem;
    width: 1rem;
  }

  .examples {
    padding: 0 1rem;
  }

  .example {
    grid-template-areas: "image" "children";
    grid-template-columns: auto;
    justify-items: center;
    gap: 1rem;
  }

  .example_children {
    gap: 1rem;
    width: 100%;
  }

  .example_children h3 {
    text-align: center;
  }

  .example_image_container {
    background: linear-gradient(rgba(182, 66, 0, 0.64), #161616);
    padding: 1rem;
    width: 100%;
    justify-content: center;
  }

  .example_image {
    max-width: 12rem;
  }

  .footer {
    padding: 0 1rem;
    padding-bottom: 8rem;
  }

  .H2H3 {
    padding: 0 1rem;
  }

  .IVFs_header {
    padding: 2rem;
  }

  .link {
    font-size: 1rem;
    /* width: 100%; */
    box-shadow: 0.5rem 0.5rem rgba(0, 116, 182, 0.32);
  }

  .link:hover {
    box-shadow: 0.75rem 0.75rem rgba(0, 116, 182, 0.32);
  }

  .logo {
    height: 11px;
    margin-bottom: 1px;
  }

  .IVFs {
    padding: 1rem;
    gap: 1rem;
  }

  .intro p {
    padding: 0 1rem;
  }
}
